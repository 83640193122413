import React from 'react';

import PageLayout from '../layouts/PageLayout';
import SEO from '../shared/SEO';
import PageHeader from '../shared/PageHeader';

const SubscribeScreen = (): React.ReactElement => {
  return (
    <PageLayout>
      <SEO
        title="Subscribe"
        description="Subscribe to get my latest posts and projects updates by email"
      />
      <PageHeader>Subscribe to the newsletter</PageHeader>
    </PageLayout>
  );
};

export default SubscribeScreen;
